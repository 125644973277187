import React from "react";


const SubmenuMap = () => {

	return (
		<>
			<div className="conatiner-fluid pe-0 ps-0">
				<a  href="https://maps.google.com?q=60+Tucker%27s+Point+Dr.+Hamilton+Parish+HS02+Bermuda">
					<img style={{width:"100%"}} alt="map" src="https://maps.googleapis.com/maps/api/staticmap?center=60+Tucker%2527s+Point+Dr.+Hamilton+Parish+HS02+Bermuda&amp;zoom=15&amp;size=640x180&amp;scale=2&amp;markers=size:mid%7C60+Tucker%2527s+Point+Dr.+Hamilton+Parish+HS02+Bermuda&amp;sensor=false&amp;key=AIzaSyBX6a6cBRBm2xqSNHd9GBDI7ylSH7ZGpOg&amp;signature=qszTFSgYjUPX-YKQtBzKsWEhfU8="></img>
				</a>
			</div>
		</>
		
	);
};

export default SubmenuMap;

import React from "react";
import { Link } from "react-router-dom";
import LoginHeader from "../../Common/LoginHeader";
import PagesBody from "./FooterPagesBody";
import LoginFooter from "../../Common/LoginFooter";
import "../../css/privacy.css";

const Privacy = () => {
  return (
    <>
      <LoginHeader></LoginHeader>
      <PagesBody>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 footerpages-container-child common-pages-body">
          <div className="col-12 col-xl-12">
            <h1 className="common-pages-text">CeoCARD PRIVACY POLICY</h1>
            <br></br>
          </div>

          <div className="col-12 col-xl-12 text-justify">
            <p>
              Your privacy is very important to us. We want to make your
              membership experience as enjoyable and rewarding as possible and
              we want you to use our site and membership opportunities with
              complete confidence. CeoCard has created this Privacy Policy to
              demonstrate our firm commitment to privacy and security. This
              Privacy Policy describes how CeoCard collects information from its
              users and members of CeoCard membership services (the "Services"),
              how we use the information we collect and the choices you have
              concerning the collection and use of such information. Please read
              this Privacy Policy carefully. <br></br>
            </p>

            <h3>Collection and Use of Information.</h3>
            <p>
              Members are asked to provide certain information when they apply
              for membership such as name, address, telephone number, billing
              information (such as a credit card number). The personal
              information collected from members during the registration process
              is used to manage each Member's account (such as billing and
              collections).
            </p>
            <p>
              To better understand its members, CeoCard may collect consumer,
              demographics and other publicly available information from
              third-parties. CeoCard may use this data to provide product and
              service offers. We may use data in aggregate to negotiate
              additional benefits, or develop products and offers and to better
              serve our members.
            </p>
            <p>
              CeoCard collects information from members who use the various
              parts of our Services. We use this information primarily to
              provide a customized experience as you use our Services. <br></br>
            </p>
            <p>
              CeoCard may share identifying information (like Name and address)
              with affiliates or business partners for their use in delivering
              product and service offers to members. Affiliates or business
              partners are not allowed to use CeoCard data for any other purpose
              other than CeoCard approved programs designed to better serve
              members. <br></br>
            </p>
            <p>
              In instances where CeoCard and a partner jointly promote the
              Services, CeoCard may provide the affiliate certain personal
              information, as a result of the joint promotion. In this instance,
              the information may not be used by the partner for any other
              purpose. CeoCard may also generate aggregate profiles from
              information provided during registration (such as the total
              number, but not the names, of members). We may use this aggregated
              and non-identifying information to sell advertisements that appear
              on the Services. <br></br>
            </p>
            <p>
              We may use registration information to contact you in a variety of
              ways. We may send you offers through email, phone or regular mail
              of CeoCard products and services or offers on behalf of selected
              affiliates. <br></br>
            </p>

            <p>
              Personal information that we collect about you includes but is not
              limited to your full name, email addresses, phone numbers,
              birthdate, credit card information, billing address, shipping
              address, LinkedIn link, Twitter handle, profile picture, and
              professional information including the name of your company and
              your professional title. Other personal information may be
              collected during the process of redeeming partner benefits,
              including but not limited to frequent flyer numbers, account
              usernames, membership IDs, and additional/alternate methods of
              contact including phone numbers, email addresses, etc.
            </p>
            <p>
              We use your personal information to verify your age (CeoCard
              members must be 21 years or older), to verify your identity, to
              review your acceptance into CeoCard Membership, as a necessary
              requirement for redeeming some benefits with our benefit partners,
              for billing purposes such as charging your membership fees, when
              we need to communicate directly with you, and for sending you
              marketing offers, newsletters, and other updates.
            </p>
            <p>
              CeoCard adheres to the terms of the European Union’s General Data
              Protection Regulations (GDPR). Data is stored for as long as you
              are a prospective, current, or past member. You have the right to
              request a download of the personal data that is stored by CeoCard.
              You have the right to request that incorrect data be updated. You
              have the right to request that your personal data be deleted. You
              have the right to withdraw consent of further processing of your
              data. You have the right to lodge a complaint with the appropriate
              data protection supervisory authority. Your personal data is
              required to become a CeoCard Member and to offer you appropriate
              services and benefit redemptions. We may perform automatic
              processing of your data to personalize your experience and
              communicate more effectively with you.
            </p>
            <p>
              We may share your personal information with benefit partners as a
              requirement for processing your redemptions, payment processors to
              charge you correctly, and marketing partners to send you offers
              and updates (you can unsubscribe from these emails at any time).
            </p>

            <h3>Online Advertisements</h3>
            <p>
              CeoCard may share aggregated and non-identifying information about
              our Visitors and members collected through the registration
              process as well as through online surveys and promotions with
              select advertisers. We may use third-party advertising companies
              to serve ads when you visit our website. These companies may use
              information (not including your name, address, email address or
              telephone number) about your visits to this and other websites in
              order to provide advertisements on this site and other sites about
              goods and services that may be of interest to you. <br></br>
            </p>

            <h3>Voluntary Surveys</h3>
            <p>
              We may periodically conduct both business and individual surveys.
              We encourage members to participate in these voluntary surveys
              because they provide us with important information that helps us
              to improve the types of services we offer and how we provide them
              to you. Your personal information and responses will remain
              confidential, even if the survey is conducted by a third-party.{" "}
              <br></br>
            </p>
            <p>
              We take the information we receive from individuals responding to
              our surveys and combine (or aggregate) it with the responses of
              other members to create broader, generic responses to the survey
              questions (such as gender, age, residence, hobbies, education,
              employment, industry sector, or other demographic information). We
              then use the aggregated information to improve the quality of our
              services to you, and to develop new services and products. This
              aggregated, non-personally identifying information may be shared
              with third-party partners. <br></br>
            </p>

            <h3>Special Cases</h3>
            <p>
              It is CeoCard's policy not to use or share the personal
              information in ways unrelated to the ones described above without
              also providing an opportunity to opt out or otherwise prohibit
              such unrelated uses. However, CeoCard may disclose personal
              information or information regarding use of the Services if, for
              any reason, in our sole discretion, we believe that it is
              reasonable to do so, including: to satisfy laws, regulations or
              other legal requests for such information; to disclose information
              that is necessary to identify, contact or bring legal action
              against someone who may be violating our Acceptable Use Policy or
              other policies; to operate the Services properly; or to protect
              CeoCard, our Services, our employees or our members. <br></br>
            </p>

            <h3>"Cookies" and How Ceo Card May Use Them </h3>
            <p>
              A "cookie" is a small data file that can be placed on your hard
              drive when you visit certain websites. CeoCard may use cookies to
              collect, store, and sometimes track information for statistical
              purposes to operate and improve the products and services we
              provide and to manage our network and property. If you have
              created your own Personal Start Page, we may use a cookie to save
              your settings and to provide customizable and personalized
              services. Additionally, be aware that if you visit non-CeoCard
              websites where you are prompted to log in or that are
              customizable, you may be required to accept cookies. Advertisers
              and partners may also use their own cookies. We do not control use
              of these cookies and expressly disclaim responsibility for
              information collected through them. <br></br>
            </p>

            <h3>Public Forums</h3>
            <p>
              Any information that you disclose in public areas of our websites
              or the Internet may become public information. You should exercise
              caution when deciding to disclose personal information in these
              areas.<br></br>
            </p>

            <h3>CeoCard's Commitment to Data Security</h3>
            <p>
              CeoCard has security measures in place to protect the loss,
              misuse, and alteration of the information under our control. While
              we make every effort to ensure the integrity and security of our
              network and systems, we cannot guarantee that our security
              measures will prevent third-party "hackers" from illegally
              obtaining this information. We will never sell your information to
              a third party. <br></br>
            </p>

            <h3>How to Access or Modify Your Account Information</h3>
            <p>
              CeoCard offers members the opportunity to access or modify
              information provided during registration. <br></br>
            </p>

            <h3>Questions About CeoCard's Privacy Policy</h3>
            <p>
              Questions about this Privacy Policy or the practices described
              herein should be directed to us at{" "}
              <Link to="mailto:contact@Ceocard.com" target="_blank">
                contact@Ceocard.com
              </Link>
              .
            </p>
            <p>
              If you have any additional questions about this Privacy Policy or
              the practices described herein, you may contact:
            </p>
            <p>
              Customer Service: CeoCard 540 Madison Ave, 29th Floor, New York,
              New York 10022.<br></br>
            </p>

            <h3>Revisions to This Policy</h3>
            <p>
              CeoCard reserves the right to revise, amend, or modify this policy
              and our other policies and agreements at any time and in any
              manner. Any revision, amendment, or modification will be posted on
              our website:{" "}
              <Link to="https://Ceocard.com" target="_blank">
                https://Ceocard.com
              </Link>
              .
            </p>
          </div>
        </div>
      </PagesBody>
      <LoginFooter></LoginFooter>
    </>
  );
};

export default Privacy;
